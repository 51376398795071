export class StaffResponse {
    Id: string;
    CompanyId:string;
    FirstName:string;
    LastName:string;
    TcNo:string;
    Phone:string;
    Email:string;
    Address:string;
    Position:string;
    BloodGroup:string;
    Education:string;
    Birthday: Date;
    StartDate: Date;
    CreatedAt: Date;
    UpdatedAt: Date;
    ContactName:string;
    ContactAddress:string;
    ContactPhone:string;
    StaffStatus:string;

    StaffCertificates:any[];
    StaffDocuments:any[] =[];
  
}

export class StaffRequest {
    Id: string;
    CompanyId:string;
    FirstName:string;
    LastName:string;
    TcNo:string;
    Phone:string;
    Email:string;
    Address:string;
    Position:string;
    BloodGroup:string;
    Education:string;
    Birthday: string;
    StartDate: string;
    CreatedAt: Date;
    UpdatedAt: Date;
    ContactName:string;
    ContactAddress:string;
    ContactPhone:string;
    StaffStatus:string;
    StaffCertificates:any[];
    StaffDocuments:any[] =[];

    Deleted: boolean;
}
  