export class UserRoleResponse {
    Id: string;
    CompanyId: string  | number;
    RoleId: string | number;

    constructor() {
    }
}
export class UserRoleRequest {
    Id: string;
    CompanyId: string;
    RoleId: string;

    constructor() {
    }
}
