import { InvoiceDetailResponse } from "./invoice-detail";
import { OfferResponse } from "./offer";

export class InvoiceResponse {
    Id: string;
    CompanyId:string;
    OfferId: string;
    CustomerId:string;
    ApprovalSupervisor: string;
    Subject: string;
    CustomerName: string;
    OfferNo: string;
    Approved: boolean;
    Deleted: boolean;
    SecondCustomerId:string;
    SecondSubject:string;
    SecondApproved:boolean;
    Offer: OfferResponse;
    Notes:string;
    InvoiceDetails: any[] = [];
    InvoiceCostDetails: any[] = [];
    FirstInvoiceTotalCost:string;
    SecondInvoiceTotalCost:string;
    FirstCostInvoiceTotalCost:string;
    SecondCostInvoiceTotalCost:string;

}

export class InvoiceRequest {
    Id: string;
    CompanyId:string;
    OfferId: string;
    CustomerId:string;
    ApprovalSupervisor: string;
    Subject: string;
    CustomerName: string;
    OfferNo: string;
    Approved: boolean;
    Deleted: boolean;
    SecondCustomerId:string;
    SecondSubject:string;
    SecondApproved:boolean;
    Offer: OfferResponse;
    Notes:string;

    InvoiceDetails: any[] = [];
    InvoiceCostDetails: any[] = [];
    FirstInvoiceTotalCost:string;
    SecondInvoiceTotalCost:string;
    FirstCostInvoiceTotalCost:string;
    SecondCostInvoiceTotalCost:string;
}
