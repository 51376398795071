import { RolePermissionRequest } from "./role-permission";

export class RoleResponse {
    Id: string;
    CompanyId: string;
    RoleType: string;
    Name: string;

    RolePermissions: RolePermissionRequest[];

    constructor() {
        this.RolePermissions=[];
    }
}

export class RoleRequest {
    Id: string;
    CompanyId: string;
    RoleType: string;
    Name: string;
    Deleted: boolean;
    
    RolePermissions: RolePermissionRequest[];

    constructor() {
        this.RolePermissions=[];
    }
}
  