import { CompanyLicense } from './company-license'
import { CompanyTemplate } from './company-template'
import { SMTPConfiguration } from './smtp-configuration'

export class CompanyResponse {
	Id: string
	ParentCompanyId: string
	Name: string
	NameOnPdf: string
	LongName: string
	Address: string
	ImageSelector: string
	PDFTemplate: string
	Fax: string
	Phone: string
	Email: string
	Logo: string

	CompanyLicense: CompanyLicense
	CompanyTemplate: CompanyTemplate
	SMTPConfiguration: SMTPConfiguration
	ChildCompanies: CompanyResponse[]
	ParentCompany: CompanyResponse
	ParentCompanyName: string

	EntryParagraphOne: string
	EntryParagraphOneEn: string
	EntryParagraphTwo: string
	EntryParagraphTwoEn: string

	EndingParagraphOne: string
	EndingParagraphOneEn: string
	EndingParagraphTwo: string
	EndingParagraphTwoEn: string

	PaymentTerms: string
	PaymentTermsEn: string

	Customer1Label: string
	Customer1LabelEn: string
	Customer1Type: string
	Customer2Label: string
	Customer2LabelEn: string
	Customer2Type: string
	Customer3Label: string
	Customer3LabelEn: string
	Customer3Type: string

	OfferValidityText: string
	OfferValidityTextEn: string

	WebSite: any

	CompanyOfferCostDetails: any[]
	CompanyOfferNotes: {
		CompanyId: number
		Id: number
		CreatedAt: any
		UpdatedAt: any
		Deleted: boolean
		IsArtNote: boolean
		TextEN: string
		TextTR: string
		Type: string // Include or exclude
		TransportType: string // Domestic, export, import
		TransportMethod: string // Air, land, sea
	}[]

	constructor() {
		this.ChildCompanies = []
		this.CompanyLicense = new CompanyLicense()
		this.CompanyTemplate = new CompanyTemplate()
		this.SMTPConfiguration = new SMTPConfiguration()
		this.CompanyOfferCostDetails = []
		this.CompanyOfferNotes = []
	}
}

export class CompanyRequest {
	Id: string
	ParentCompanyId: string
	Name: string
	NameOnPdf: string
	LongName: string
	ImageSelector: string
	PDFTemplate: string

	EntryParagraphOne: string
	EntryParagraphOneEn: string
	EntryParagraphTwo: string
	EntryParagraphTwoEn: string

	EndingParagraphOne: string
	EndingParagraphOneEn: string
	EndingParagraphTwo: string
	EndingParagraphTwoEn: string

	PaymentTerms: string
	PaymentTermsEn: string

	Customer1Label: string
	Customer1LabelEn: string
	Customer1Type: string
	Customer2Label: string
	Customer2LabelEn: string
	Customer2Type: string
	Customer3Label: string
	Customer3LabelEn: string
	Customer3Type: string
	WebSite: any
	Address: string
	Fax: string
	Phone: string
	Email: string
	Logo: string
	Deleted: boolean
}
