export class PersonelTypeResponse {
    Id: string;
    CompanyId: string;
    Name: string;
}

export class PersonelTypeRequest {
    Id: string;
    CompanyId: string;
    Name: string;
    Deleted: boolean;
}
  