export class VehicleRequest {
	Id: string;
	CompanyId: string;
	UserId: string;
	VehicleMaintenances: any[] = [];
	BrandName: string;
	VehicleImage: string;
	Plate: string;
	LicenceFileName: string;
	DetailsFileName: string;
	CreatedAt: Date;
	UpdatedAt: Date;
	Deleted: boolean;

	TachographUpdateStartDate: string;
	TachographUpdateEndDate: string;
	DriverTachographUpdateStartDate: string;
	DriverTachographUpdateEndDate: string;
	ReferenceKm:number;
	ReferenceKmDate:string;
	DriverFee: string;
	OtherFee: string;

	InspectionFee: string;
	InspectionStartDate: string;
	InspectionEndDate: string;
	InspectionFileName: string;

	RoadRelevenceFee: string;
	RoadRelevenceStartDate: string;
	RoadRelevenceEndDate: string;
	RoadRelevenceFileName: string;

	RoadworthinessInspectionFee: string;
	RoadworthinessInspectionStartDate: string;
	RoadworthinessInspectionEndDate: string;

	SummerTireDate: string;
	WinterTireDate: string;

	TrafficInsuranceFee: string;
	TrafficInsuranceStartDate: string;
	TrafficInsuranceEndDate: string;
	TrafficInsuranceFileName: string;
	AnnualDomesticFuelQuantityFee:string;

	GreenCardInsuranceFee: string;
	GreenCardInsuranceStartDate: string;
	GreenCardInsuranceEndDate: string;
	GreenCardInsuranceFileName: string;

	CemtDocumentFee: string;
	CemtDocumentStartDate: string;
	CemtDocumentEndDate: string;
	CemtDocumentFileName: string;

	LDocumentFee: string;
	LDocumentStartDate: string;
	LDocumentEndDate: string;
	LDocumentFileName: string;

	TachographFee: string;
	TachographStartDate: string;
	TachographEndDate: string;
	TachographFileName: string;

	FirstAidFee: string;
	FirstAidStartDate: string;
	FirstAidEndDate: string;
	FirstAidFileName: string;

	YearlyMtvFee: string;
	YearlyMtvStartDate: string;
	YearlyMtvEndDate: string;
	YearlyMtvFileName: string;

	Trips: any[] = [];
}

export class VehicleResponse {
	Id: string;
	CompanyId: string;
	UserId: string;
	VehicleMaintenances: any[] = [];
	BrandName: string;
	VehicleImage: string;
	Plate: string;
	LicenceFileName: string;
	DetailsFileName: string;
	CreatedAt: Date;
	UpdatedAt: Date;
	Deleted: boolean;
	AnnualDomesticFuelQuantityFee:string;
	TachographUpdateStartDate: string;
	TachographUpdateEndDate: string;
	DriverTachographUpdateStartDate: string;
	DriverTachographUpdateEndDate: string;
	ReferenceKm:number;
	ReferenceKmDate:string;
	DriverFee: string;
	OtherFee: string;

	InspectionFee: string;
	InspectionStartDate: string;
	InspectionEndDate: string;
	InspectionFileName: string;

	RoadRelevenceFee: string;
	RoadRelevenceStartDate: string;
	RoadRelevenceEndDate: string;
	RoadRelevenceFileName: string;

	RoadworthinessInspectionFee: string;
	RoadworthinessInspectionStartDate: string;
	RoadworthinessInspectionEndDate: string;

	SummerTireDate: string;
	WinterTireDate: string;

	TrafficInsuranceFee: string;
	TrafficInsuranceStartDate: string;
	TrafficInsuranceEndDate: string;
	TrafficInsuranceFileName: string;

	GreenCardInsuranceFee: string;
	GreenCardInsuranceStartDate: string;
	GreenCardInsuranceEndDate: string;
	GreenCardInsuranceFileName: string;

	CemtDocumentFee: string;
	CemtDocumentStartDate: string;
	CemtDocumentEndDate: string;
	CemtDocumentFileName: string;

	LDocumentFee: string;
	LDocumentStartDate: string;
	LDocumentEndDate: string;
	LDocumentFileName: string;

	TachographFee: string;
	TachographStartDate: string;
	TachographEndDate: string;
	TachographFileName: string;

	FirstAidFee: string;
	FirstAidStartDate: string;
	FirstAidEndDate: string;
	FirstAidFileName: string;

	YearlyMtvFee: string;
	YearlyMtvStartDate: string;
	YearlyMtvEndDate: string;
	YearlyMtvFileName: string;

	Trips: any[] = [];
}
