export class EmailTemplateResponse {
    Id: string;
    CompanyId: string;
    Name: string;
    Content: string;
}

export class EmailTemplateRequest {
    Id: string;
    CompanyId: string;
    Name: string;
    Content: string;
    Deleted: boolean;
}
  