export class StorageRequest {
    Id:number;
    OfferId:number;
    CompanyId:number;
    UserId:number;
    PartQuantity:number;
    Insurance:string;
    MonthlyRent:number;
    MonthlyRentUnit:string;
    StoreRoomNo:string;
    RentExpirationDate:string;
    WarehouseEntranceDate:string;
    LastRaiseAmount:string;
    LastPaymentReceivedDate:string;
    LastPaymentReceivedDateInfo:string;
    GoodsListTr:string;
    GoodsListEn:string;
    CreatedAt:Date;
    UpdatedAt:Date;
    IsStockRequestCompleted:boolean;
    StorageInvoices:any[]=[];
    Deleted:boolean;
    BilledCustomerId :number;
    TotalCost:number;
}
export class StorageResponse {
    Id:number;
    OfferId:number;
    CompanyId:number;
    UserId:number;
    PartQuantity:number;
    Insurance:string;
    MonthlyRent:number;
    MonthlyRentUnit:string;
    StoreRoomNo:string;
    RentExpirationDate:string;
    WarehouseEntranceDate:string;
    LastRaiseAmount:string;
    LastPaymentReceivedDate:string;
    LastPaymentReceivedDateInfo:string;
    GoodsListTr:string;
    GoodsListEn:string;
    CreatedAt:Date;
    UpdatedAt:Date;
    IsStockRequestCompleted:boolean;
    StorageInvoices:any[]=[];
    BilledCustomerId :number;
    TotalCost:number;
}
