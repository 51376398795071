import { UserFamilyProfileResponse } from './user-family-profile'
import { UserRoleResponse } from './user-role'

export class UserResponse {
	Id: string
	CompanyId: string
	FirstName: string
	LastName: string
	TCKNO: string
	Password: string
	Email: string
	Phone: string
	Photo: string
	FailAttempts: number

	UserRoles: UserRoleResponse[]
	UserFamilyProfiles: UserFamilyProfileResponse[]
	DepartmentName: string
	PersonelTypeName: string

	constructor() {
		this.UserRoles = []
	}
}

export class UserRequest {
	Id: string
	CompanyId: string | number
	FirstName: string
	LastName: string
	TCKNO: string
	Password: string
	Email: string
	Phone: string
	Photo: string
	FailAttempts: number
	Deleted: boolean

	UserRoles: UserRoleResponse[]
	UserFamilyProfiles: UserFamilyProfileResponse[]

	constructor() {
		this.UserRoles = []
	}
}
