export class DepartmentResponse {
    Id: string;
    CompanyId: string;
    Name: string;
}

export class DepartmentRequest {
    Id: string;
    CompanyId: string;
    Name: string;
    Deleted: boolean;
}
  