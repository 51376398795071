import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {MarkdownEditorDirective} from './markdown-editor.directive'

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    MarkdownEditorDirective,
  ],
  exports: [
    MarkdownEditorDirective,
  ]
})
export class SmartadminEditorsModule { }
