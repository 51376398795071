export class FiletrackingResponse {
	Id: string
	UserId: string
	OfferId: string
	CompanyId: string
	CustomerReport: string
	OriginReport: string
	GoodsHitDate: string
	InfoContainerPiece: string
	OperationContainerPiece: string
	ConsignmentWeight: string
	OfferedWeight: string
	ETA: string
	CustomerTRDate: string
	PartQuantity: number
	DocumentsHitDate: string
	WantedDeliveryDate: string
	AddressDetails: string
	OrdinoDate: string
	IthatStartDate: string
	IthatEndDate: string
	ContainerOutDate: string
	FinalDeliveryStartDate: string
	FinalDeliveryEndDate: string
	SignedDocumentsTransmit: string
	InvoiceTransmitToCustomer: string
	IsDamage: boolean
	DamageDescription: string
	CreatedAt: Date
	UpdatedAt: Date
	CustomerToBeBilled: string
	ETD: string
	CustomerReportChecked: boolean
	InvoiceTransmitToCustomerChecked: boolean
	OriginReportChecked: boolean
	SignedDocumentsTransmitChecked: boolean
	Forwarder: number
	BookingAgent: number
	OriginAgent: number
	DestinationAgent: number
	AddressInfo: string
	HandlingInfo: string
	ConsignmentNo: string
	VolumeOnOffer: string
	WeightOnOffer: string
	WeightOnConsignment: string
	_ActualVolume: string
	_ActualWeight: string
	WantedPackagingDate: string
	CustomDocumentsReachedBenice: string
	CustomsExportEnd: string
	DateOfConsignmentPreparation: string
	CustomClearanceDate: string
	IsWorkInstructionsAfter12Hours: boolean
	WorkInstructionsAfter12Hours: string
	IsSignedDocumentsAfter12Hours: boolean
	SignedDocumentsAfter12Hours: string
	TemporaryImportDeclarationDate: string
	IsTemporaryImport30Days: boolean
	TemporaryImport30Days: string
	IsExtraCost: boolean
	ExtraCost: string
	HadWarning: boolean
	HasInsurance: boolean
	InsuranceInfo: string
	InsuranceStartDate: string
	InsuranceEndDate: string
}

export class FiletrackingRequest {
	Id: string
	UserId: string
	OfferId: string
	CompanyId: string
	CustomerReport: string
	OriginReport: string
	GoodsHitDate: string
	InfoContainerPiece: string
	OperationContainerPiece: string
	ConsignmentWeight: string
	OfferedWeight: string
	ETA: string
	PartQuantity: number
	CustomerTRDate: string
	DocumentsHitDate: string
	WantedDeliveryDate: string
	AddressDetails: string
	OrdinoDate: string
	IthatStartDate: string
	IthatEndDate: string
	ContainerOutDate: string
	FinalDeliveryStartDate: string
	FinalDeliveryEndDate: string
	SignedDocumentsTransmit: string
	InvoiceTransmitToCustomer: string
	IsDamage: boolean
	DamageDescription: string
	CreatedAt: Date
	UpdatedAt: Date
	CustomerToBeBilled: string
	Deleted = false
	ETD: string
	CustomerReportChecked: boolean
	InvoiceTransmitToCustomerChecked: boolean
	OriginReportChecked: boolean
	SignedDocumentsTransmitChecked: boolean
	Forwarder: number
	BookingAgent: number
	OriginAgent: number
	DestinationAgent: number
	AddressInfo: string
	HandlingInfo: string
	ConsignmentNo: string
	VolumeOnOffer: string
	WeightOnOffer: string
	WeightOnConsignment: string
	_ActualVolume: string
	_ActualWeight: string
	WantedPackagingDate: string
	CustomDocumentsReachedBenice: string
	CustomsExportEnd: string
	DateOfConsignmentPreparation: string
	CustomClearanceDate: string
	IsWorkInstructionsAfter12Hours: boolean
	WorkInstructionsAfter12Hours: string
	IsSignedDocumentsAfter12Hours: boolean
	SignedDocumentsAfter12Hours: string
	TemporaryImportDeclarationDate: string
	IsTemporaryImport30Days: boolean
	TemporaryImport30Days: string
	IsExtraCost: boolean
	ExtraCost: string
	HadWarning: boolean
	HasInsurance: boolean
	InsuranceInfo: string
	InsuranceStartDate: string
	InsuranceEndDate: string
}
