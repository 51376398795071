import { CustomerDocument } from './customer-document'
export class CustomerResponse {
	Id: string
	CompanyId: number = 0
	ParentCompanyId: number = 0
	ParentCompanyName: string = ''
	CustomerPosition: string = ''
	CompanyName: string = ''
	Sector: string = ''
	FullName: string
	Name: string = ''
	Title: string = ''
	Surname: string = ''
	TCKNo: string = ''
	Type: string = ''
	Country: string = ''
	FIDIMember: boolean = false
	Phone: string = ''
	Phone2: string = ''
	Phone3: string = ''
	Fax: string = ''
	Website: string = ''
	Email: string = ''
	Email2: string = ''
	Address: string = ''
	Description: string = ''
	TaxOffice: string = ''
	TaxNo: string = ''
	BillingAddress: string = ''
	Titre: string = ''
	Job: string = ''
	ParentCustomerId: number = 0
	CustomerType: string = ''
	CustomerTitle: string = ''
	PositionOther: string = ''
	JobOther: string = ''
	TaxAdministration: string = ''
	TaxNumber: string = ''
	CustomerDocuments: CustomerDocument[]
}

export class CustomerRequest {
	Id: string
	CompanyId: number = 0
	CompanyName: string = ''
	ParentCompanyId: number = 0
	Sector: string = ''
	CustomerPosition: string = ''
	FullName: string
	Name: string = ''
	Title: string = ''
	Surname: string = ''
	TCKNo: string = ''
	Type: string = ''
	Country: string = ''
	FIDIMember: boolean = false
	Phone: string = ''
	Phone2: string = ''
	Phone3: string = ''
	Fax: string = ''
	Website: string = ''
	Email: string = ''
	Email2: string = ''
	Address: string = ''
	Description: string = ''
	TaxOffice: string = ''
	TaxNo: string = ''
	BillingAddress: string = ''
	Titre: string = ''
	Job: string = ''
	ParentCustomerId: number = 0
	Deleted: boolean
	CustomerType: string = ''
	CustomerTitle: string = ''
	PositionOther: string = ''
	JobOther: string = ''
	TaxAdministration: string = ''
	TaxNumber: string = ''
	CustomerDocuments: CustomerDocument[]
}
