export class OperationResponse {
	CompanyId: number;
	OfferId: number;
	Id: number;
	ActualVolume: string;
	ActualVolumeInfo: string;

	InTransit: boolean;
	OperationStartDate: Date;
	OperationEndDate: Date;
	FileCost: number;
	TripCost: number;
	StartHour: string;
	EndHour: string;
	RentedVehicles: string;
	AsyaVehicles: string;
	Notes: string;
	Day: string;
	PrintCustomerName: string = null;
	PrintLocation: string = null;
	PrintNo: string = null;
	CreatedAt: Date;
	UpdatedAt: Date;
	Closed: boolean;
	Kilometer: number;
	IsRentedVehicle:boolean;
	RentedVehiclePlate:string;
	OperationCosts= {};
	OperationDays:any[] = [];
	MaterialRequests:any[] = [];
	IsStockRequestCompleted:boolean;
}

export class OperationRequest {
	CompanyId: number;
	OfferId: number;
	Id: number;
	ActualVolume: string;
	ActualVolumeInfo: string;
	FileCost: number;
	TripCost: number;

	InTransit: boolean;
	OperationStartDate: Date;
	OperationEndDate: Date;
	IsStockRequestCompleted:boolean;
	StartHour: string;
	EndHour: string;
	RentedVehicles: string;
	AsyaVehicles: string;
	Notes: string;
	Day: string;
	PrintCustomerName: string = null;
	PrintLocation: string = null;
	PrintNo: string = null;
	CreatedAt: Date;
	UpdatedAt: Date;
	Closed: boolean;
	Kilometer: number;
	IsRentedVehicle:boolean;
	RentedVehiclePlate:string;
	OperationCosts = {};
	OperationDays:any[] = [];
	MaterialRequests:any[] = [];
}
