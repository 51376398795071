import { CompanyResponse } from "./company";

export class SMTPConfiguration {
    CompanyId: string;
    ServerName: string;
    Port: string;
    Username: string;
    Password: string;
    SSL: boolean;

    Company: CompanyResponse;
}